
$('a:not([href^="tel:"]):not([target])').on('click tap', function(e) {

  var ref = location.href;
  var url = $(this).attr('href');

  if( ref.indexOf(url.replace(/#.*$/, '')) != -1 && url.indexOf('#') != -1 ) {

    var speed = 300;
    var href= url.match(/#.*$/);
    var target = $(href[0]);
    var position = target.offset().top - $('.js-header').outerHeight();
    $('body, html').animate({ scrollTop: position }, speed, 'swing');
    return false;

  }

});


$('.js-accrodion-btn').on('click tap', function(e) {
  $(this).next('.js-accrodion-target').slideToggle(
    function() {
      $(window).trigger('resize');
    }
  );
  $(this).toggleClass('is-active');
  $(window).trigger('resize');
});


if($('.js-view-box-toggle')[0] && $('.js-view-box')[0]) {

  $('.js-view-box').each(function() {
    if($(this).outerHeight() < 72) {
      $(this).toggleClass('is-active');
      $(this).next('.js-view-box-toggle').hide();
    }
  });

  $('.js-view-box-toggle').on('click tap', function(e) {
    $(this).prev('.js-view-box').toggleClass('is-active');
    $(this).remove();
  });

}


$('.g-menu__container').on('scroll', function() {
  $(window).trigger('scroll');
});


$(window).on('load scroll', function() {

  if( $('.js-pagetop')[0] ) {
    var h = $(window).height();
    var scrollTop = $(window).scrollTop();
    if (scrollTop > h / 2) {
      $('.js-pagetop').addClass('is-active');
    }
    else {
      $('.js-pagetop').removeClass('is-active');
    }
  }

});


$(window).on('load resize', function() {

  var headerH = $('.js-header').outerHeight();

  // detail header
  if( $('.js-detail-header')[0] ) {
    $('.js-detail-header').css({
      'top': headerH,
    });
  }

  // .woocommerce .product .summary
  if( $('.woocommerce .product .summary')[0] ) {
    $('.woocommerce .product .summary').css({
      'top': headerH,
    });
  }

  // .cart_totals
  if( $('.cart_totals')[0] ) {
    $('.cart_totals').css({
      'top': headerH,
    });
  }

  // .woocommerce-checkout-review-order
  if( $('.woocommerce-checkout-review-order')[0] ) {
    $('.woocommerce-checkout-review-order').css({
      'top': headerH,
    });
  }

  $('.js-match-height-parent').each(function() {

    var maxH = 0;
    var children = $(this).children();

    children.css('height', 'auto');

    children.each(function() {
      var h = $(this).outerHeight();
      if(maxH < h) {
        maxH = h;
      }
    });

    children.css('height', maxH);

  });

});

// Lazy Load
$(window).on('load resize scroll', function() {

  // Background
  $('[data-lazy-background]').each(function() {
    if ($(window).scrollTop() + $(window).height() > $(this).offset().top) {
      $(this).css('background-image', 'url('+$(this).data('lazy-background')+')');
      $(this).removeAttr('data-lazy-background');
    }
  });

  // img
  $('[data-lazy-img]').each(function() {
    if ($(window).scrollTop() + $(window).height() > $(this).offset().top) {
      $(this).attr('src', $(this).data('lazy-img'));
      $(this).removeAttr('data-lazy-img');
    }
  });

});


$(window).on('load', function() {

  if( $('.js-header')[0] ) {
    // Sticky Polyfill
    var elem = document.querySelector('.js-header');
    Stickyfill.add(elem);
  }

  if( $('.js-detail-header')[0] ) {
    // Sticky Polyfill
    var elem = document.querySelector('.js-detail-header');
    Stickyfill.add(elem);
  }

  if( $('.entry-summary')[0] ) {
    // Sticky Polyfill
    var elem = document.querySelector('.entry-summary');
    Stickyfill.add(elem);
  }

  if( $('.js-truncate')[0] ) {
    var truncate = Truncator.truncate;
    var truncateElm = document.getElementsByClassName('js-truncate');
    for (var i = 0; i < truncateElm.length; i++) {
      var line = truncateElm[i].dataset.line ? truncateElm[i].dataset.line : 2;
      truncate(truncateElm[i], truncateElm[i].textContent, { line: parseInt(line), ellipsis: '...' });
    }
  }

  $('.js-menu-toggle').on('click tap', function() {
    var status = $('body').attr('data-menu');
    if( status == 'active' ) {
      $('body').attr('data-menu', '');
    } else {
      $('body').attr('data-menu', 'active');
    }
  });

  $('.js-select-toggle').on('click tap', function() {
    var status = $('body').attr('data-select');
    if( status == 'active' ) {
      $('body').attr('data-select', '');
    } else {
      $('body').attr('data-select', 'active');
    }
  });

  $('.js-search-toggle').on('click tap', function() {
    var status = $('body').attr('data-search');
    if( status == 'active' ) {
      $('body').attr('data-search', '');
    } else {
      $('body').attr('data-search', 'active');
    }
  });

  $('.js-cart-toggle').on('click tap', function() {
    var status = $('body').attr('data-cart');
    if( status == 'active' ) {
      $('body').attr('data-cart', '');
    } else {
      $('body').attr('data-cart', 'active');
    }
  });

  $('.js-pagetop-btn').on('click tap', function() {
    $('body, html').animate({
      scrollTop: 0
    }, 300, 'swing');
  });

  if( location.hash && $(location.hash)[0] ) {

    var hash = location.hash;

    setTimeout( function() {
      $('body, html').animate({ scrollTop: $(hash).offset().top - $('.js-header').outerHeight() }, 0, 'linear');
    }, 300 );

    $('.p-gnav-link').each(function() {
      if(location.href.indexOf($(this).attr('href')) > 0) {
      $('.p-gnav-link').removeClass('is-active');
        $(this).addClass('is-active');
      }
    });

    if( $(location.hash).hasClass('js-accrodion-btn') ) {
      $(location.hash).trigger('click');
    }

  }

  loadFile('https://fonts.googleapis.com/css?family=Josefin+Sans:400,700&display=swap', 'css');
  loadFile('https://use.fontawesome.com/releases/v5.6.1/css/all.css', 'css');

  $('body').attr('data-status', 'loaded');

  if( $('.js-visual')[0] ) {
    new Swiper('.js-visual', {
      loop: true,
      speed: 500,
      slidesPerView: 'auto',
      loopAdditionalSlides: 1,
      autoplay: {
        delay: 4000,
        disableOnInteraction: false,
      },
      autoplayDisableOnInteraction: false,
      paginationClickable: true,
      pagination: {
        el: '.js-visual-pagination',
        clickable: true
      },
    });
  }

  if( $('.js-detail-images')[0] ) {

    var detailImages = new Swiper('.js-detail-images', {
      loop: true,
      speed: 1000,
      slidesPerView: 'auto',
      loopAdditionalSlides: 1,
      autoplay: {
        delay: 4000,
        disableOnInteraction: false,
      },
      autoplayDisableOnInteraction: false,
      navigation: {
        nextEl: '.js-detail-images-next',
        prevEl: '.js-detail-images-prev',
      },
      on: {
        slideChange: function () {
          slideThumbnailActive(detailImages, '.js-detail-images-thumbnail');
        },
      },
    });

    detailImages.slideTo(0);

    $('.js-detail-images-thumbnail').on('click tap', function() {
      var index = $('.js-detail-images-thumbnail').index(this);
      detailImages.slideTo(index);
    });

    slideThumbnailActive(detailImages, '.js-detail-images-thumbnail');

  }

  if( $('.woocommerce-product-gallery__image')[0] ) {

    $('.woocommerce-product-gallery__image').each(function() {
      var src = $(this).find('img').attr('data-src');
      $('.js-product-images .swiper-wrapper').append('<li class="swiper-slide"><div style="background-image: url('+src+');"></div></li>');
    });

    if( $('.woocommerce-product-gallery__image')[1] ) {
      var productImages = new Swiper('.js-product-images', {
        loop: true,
        speed: 1000,
        slidesPerView: 'auto',
        centeredSlides: true,
        loopAdditionalSlides: 1,
        spaceBetween: 0,
        autoplay: {
          delay: 4000,
          disableOnInteraction: false,
        },
        autoplayDisableOnInteraction: false,
        navigation: {
          nextEl: '.js-product-images-next',
          prevEl: '.js-product-images-prev',
        },
        on: {
          slideChange: function () {
            slideThumbnailActive(productImages, '.woocommerce-product-gallery__image');
          },
        },
      });

      productImages.slideTo(0);

      $('.woocommerce-product-gallery__image').on('click tap', function() {
        var index = $('.woocommerce-product-gallery__image').index(this);
        productImages.slideTo(index);
      });

      slideThumbnailActive(productImages, '.woocommerce-product-gallery__image');
    } else {
      $('.js-product-images-next, .js-product-images-prev').hide();
    }

  }

});

function slideThumbnailActive(slider, thumbnail) {
  var index = slider.realIndex;
  $(thumbnail).removeClass('is-active');
  $(thumbnail).eq(index).addClass('is-active');
}


function loadFile(src, type) {

  if( type == 'js' ) {
    $('body').append('<script src="'+src+'"></script>');
  }

  else if( type == 'css' ) {
    $('body').append('<link rel="stylesheet" href="'+src+'">');
  }

  else {
    return false;
  }

}
